import React from 'react';
import './index.css';
import logo from '../oldassets/logo.png';
import telicon from '../oldassets/ico_tel.png';
import banner from '../oldassets/banner_01.jpg';
import delux from '../oldassets/delux.jpg';
import intergrain from '../oldassets/Intergrain.jpg';
import porters from '../oldassets/PORTERS.jpg';
import { Link } from 'react-router-dom';

function AboutPage() {
  return (
      <div>
        <div id="holder">
    <div id="header">
      <div id="logo"><a href="https://cdapainting.com/home"><img src={logo}
            alt="CDA Painting Solutions"/></a></div>
      <div id="moto"><img style={{verticalAlign: 'middle', marginTop: '-12px'}}
          src={telicon} alt="" width="43"
          height="43"/>&nbsp;&nbsp;<strong><span style={{fontSize: 'xx-large'}}>0419 301 725<br /></span></strong>
      </div>
    </div>
    <div id="mainNav">

      <div id="menuwrapper">
        <ul id="primary-nav">

        <li>
            <Link to="/"><span>Home</span></Link>
          </li>
          <li class="menuactive">
            <Link className="menuactive" to="/about-us"><span>About Us</span></Link>
          </li>
          <li>
            <Link to="/testimonials"><span>Testimonials</span></Link>
          </li>
          <li>
            <Link to="/gallery"><span>Gallery</span></Link>
          </li>
        </ul>
        <div class="clearb"></div>
      </div>

    </div>
    <div id="banner"><img src={banner} alt="" width="960" height="206"/>
    </div>
   
    <div id="content">
        <div id="col-1">
            <div id="col-2"> <h1>About Us</h1>
            <p>CDA Painting Solutions is a local professionally run business specialising in residential and body corporate work.</p>
                <p>When you contact us we will arrange to meet with you to discuss your requirements and also provide a <strong>free</strong> quotation.</p>
                <p>At CDA Painting Solutions we only use skilled and professional trades people.</p>
                <ul>
                <li>We offer eco-friendly and low ‘voc’ [volatile organic compounds] products</li>
                <li>We are fully insured.</li>
                <li>We offer free colour consultation.</li>
                <li>We are reliable and clean in our work.</li>
                <li>We arrange for minimal disruption to your day-to-day living while on the job.</li>
                </ul>
                <p>We care about the environment and dispose of any excess paint or volatile materials at HHW [Hazardous Waste] Collection Depots.</p>
                <p>&nbsp;</p>
                <h3>PRODUCTS WE USE</h3>
                <table class="table01" border="0" cellspacing="0" cellpadding="0">
                <tbody>
                <tr>
                <td valign="top" width="170">
                <p><img src={delux} alt="" width="150" height="73"/></p>
                </td>
                <td valign="top" width="477">
                <p><strong>Dulux Paint</strong><br />Dulux premium range of paints for coverage and longevity</p>
                </td>
                </tr>
                <tr>
                <td valign="top" width="170">
                <p><img src={porters} alt="" width="150" height="80"/></p>
                </td>
                <td valign="top" width="477">
                <p><strong>Porters Original Paints<br /></strong>Porters for decorative finishes</p>
                </td>
                </tr>
                <tr>
                <td valign="top" width="170">
                <p><img src={intergrain} alt="" width="150" height="43"/></p>
                </td>
                <td valign="top" width="477">
                <p><strong>Intergrain Timber finishes<br /></strong>For interior and exterior timber surfaces</p>
                </td>
                </tr>
                </tbody>
                </table>

            </div>
        </div>
    </div>
    <div id="footerHolder">
      <div id="footer">
        <div id="footerNav">


          <ul>

            <li><a href="https://cdapainting.com/"><span>Home</span></a>


            </li>

            <li class="menuactive"><a class="menuactive" href="https://cdapainting.com/about-us"><span>About Us</span></a>


            </li>

            <li><a href="https://cdapainting.com/testimonials"><span>Testimonials</span></a>


            </li>

            <li><a href="https://cdapainting.com/gallery"><span>Gallery</span></a>


            </li>
          </ul>
        </div>
        <div id="footerTxt">
          <p>Copyright © 2020 | All Rights Reserved
           </p>
        </div>
      </div>
    </div>
  </div>
      </div>
  );
}

export default AboutPage;
