import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import HomePage from './Home';
import AboutPage from './About';
import TestimonialsPage from './Testimonials';
import GalleryPage from './Gallery';

const App = () => (
  <Router>
    <div>
      <div className="container">
        <Route exact path="/" component={HomePage} />
        <Route exact path="/about-us" component={AboutPage} />
        <Route exact path="/testimonials" component={TestimonialsPage} />
        <Route exact path="/gallery" component={GalleryPage} />
      </div>
    </div>
  </Router>
);

export default App;