import React from 'react';
import './index.css';
import logo from '../oldassets/logo.png';
import telicon from '../oldassets/ico_tel.png';
import banner from '../oldassets/banner_01.jpg';
import { Link } from 'react-router-dom';

function TestimonialsPage() {
  return (
      <div>
        <div id="holder">
    <div id="header">
      <div id="logo"><a href="https://cdapainting.com/home"><img src={logo}
            alt="CDA Painting Solutions"/></a></div>
      <div id="moto"><img style={{verticalAlign: 'middle', marginTop: '-12px'}}
          src={telicon} alt="" width="43"
          height="43"/>&nbsp;&nbsp;<strong><span style={{fontSize: 'xx-large'}}>0419 301 725<br /></span></strong>
      </div>
    </div>
    <div id="mainNav">

      <div id="menuwrapper">
        <ul id="primary-nav">

          <li>
            <Link to="/"><span>Home</span></Link>
          </li>
          <li>
            <Link to="/about-us"><span>About Us</span></Link>
          </li>
          <li class="menuactive">
            <Link className="menuactive" to="/testimonials"><span>Testimonials</span></Link>
          </li>
          <li>
            <Link to="/gallery"><span>Gallery</span></Link>
          </li>
        </ul>
        <div class="clearb"></div>
      </div>

    </div>
    <div id="banner"><img src={banner} alt="" width="960" height="206"/>
    </div>
    <div id="content">
    <div id="col-1">
      <div id="col-2"> <h1>Testimonials</h1>
<div>
<blockquote>
<div dir="ltr">
<div>
<div>
<div>
<div>
<div>
<div>I highly recommend the services of CDA Painting Solutions. We were fortunate enough to have Michael recommended by a friend of ours, and since then we have used Michael and his team on three separate projects. Our expectations have been exceeded every time, both in terms of the finished product as well as the sheer professionalism with which Michael approaches his work. As a busy, hard to reach client, Michael's communications were extremely thorough. He was excellent at liaising with our interior designer and our builder, helping to make our projects always run smoothly and on time. Michael is truly passionate about what he does and the results show in his work. His professional excellence added enormous value to our home pre-sale and we feel that the pain finish was a big part of why we did so well at auction. On all three major projects Michael did for us (2 homes and a restaurant), we continually get feedback about what a great finish he has done. I would have no hesitation in recommending Michael and the team at CDA to anyone looking for a quality painting solution for the home or business.</div>
Warmest Regards</div>
Ben</div>
</div>
</div>
</div>
</div>
</blockquote>
</div>
<p>&nbsp;</p>
<p>Michael painted the exterior of our previous house, a Victorian in Armadale, a few years ago, and did an incredible job which included repairing weather boards and reconditioning windows. &nbsp;When we needed our new house painted we asked Michael to this time do both the interior and exterior. &nbsp;His workmanship was again outstanding and he provided great advice on colours. &nbsp;Michael is a true professional, who I can definitely recommend if you value a top quality finish.<br />Rod van Onselen<br />Canterbury</p>
<p>&nbsp;</p>
<p>Michael has painted two house for me. The quality of his work is outstanding. I have especially been impressed by the preparation work, in comparison to other house painters I have used. He has provided useful advice about colours and about different types of finish. Michael is also a friendly guy who is easy to work with.</p>
<p>D. Simpson,&nbsp;<span style={{fontSize: '13px', lineHeight: '1.5em'}}>Balaclava</span></p>
<p><span style={{fontSize: '13px', lineHeight: '1.5em'}}>&nbsp;</span></p>
<p>We approached CDA Painting after watching them transform a house nearby to us from a very tired old property to one of the best looking on the street. After meeting with Michael we were sure that he was the painter we needed to help us restore the house we were renovating. Michael provided excellent advice on colour selection. He also had some other great ideas to bring out the heritage character in our property. Michael was as passionate about our project as we were and we would highly recommend CDA Painting to anyone looking to restore their home to its former glory.</p>
<p>&nbsp;</p>
<p>&nbsp;Jeremy &amp; Natalie Hearst</p>
<p><span style={{fontSize: '13px', lineHeight: '1.5em'}}>&nbsp;</span></p> </div>
    </div>
    </div>
  <div id="footerHolder">
      <div id="footer">
        <div id="footerNav">


          <ul>

            <li><a href="https://cdapainting.com/"><span>Home</span></a>


            </li>

            <li><a href="https://cdapainting.com/about-us"><span>About Us</span></a>


            </li>

            <li class="menuactive"><a class="menuactive" href="https://cdapainting.com/testimonials"><span>Testimonials</span></a>


            </li>

            <li><a href="https://cdapainting.com/gallery"><span>Gallery</span></a>


            </li>
          </ul>
        </div>
        <div id="footerTxt">
          <p>Copyright © 2020 | All Rights Reserved
           </p>
        </div>
      </div>
    </div>
  </div>
      </div>
  );
}

export default TestimonialsPage;
