import React from 'react';
import './index.css';
import logo from '../oldassets/logo.png';
import telicon from '../oldassets/ico_tel.png';
import banner from '../oldassets/banner_01.jpg';
import gallery01 from '../oldassets/gallery/gallery01.jpg';
import gallery02 from '../oldassets/gallery/gallery02.jpg';
import gallery03 from '../oldassets/gallery/gallery03.jpg';
import gallery04 from '../oldassets/gallery/gallery04.jpg';
import gallery05 from '../oldassets/gallery/gallery05.jpg';
import gallery06 from '../oldassets/gallery/gallery06.jpeg';
import gallery07 from '../oldassets/gallery/gallery07.jpeg';
import gallery08 from '../oldassets/gallery/gallery08.jpeg';
import gallery09 from '../oldassets/gallery/gallery09.jpeg';
import gallery10 from '../oldassets/gallery/gallery10.jpeg';
import gallery11 from '../oldassets/gallery/gallery11.jpeg';
import gallery12 from '../oldassets/gallery/gallery12.jpeg';
import gallery13 from '../oldassets/gallery/gallery13.jpeg';
import gallery14 from '../oldassets/gallery/gallery14.jpeg';
import gallery15 from '../oldassets/gallery/gallery15.jpg';
import gallery16 from '../oldassets/gallery/gallery16.jpg';
import gallery17 from '../oldassets/gallery/gallery17.jpeg';
import thumbGallery01 from '../oldassets/gallery/thumbs/thumb01.jpg';
import thumbGallery02 from '../oldassets/gallery/thumbs/thumb02.jpg';
import thumbGallery03 from '../oldassets/gallery/thumbs/thumb03.jpg';
import thumbGallery04 from '../oldassets/gallery/thumbs/thumb04.jpg';
import thumbGallery05 from '../oldassets/gallery/thumbs/thumb05.jpg';
import thumbGallery06 from '../oldassets/gallery/thumbs/thumb06.jpeg';
import thumbGallery07 from '../oldassets/gallery/thumbs/thumb07.jpeg';
import thumbGallery08 from '../oldassets/gallery/thumbs/thumb08.jpeg';
import thumbGallery09 from '../oldassets/gallery/thumbs/thumb09.jpeg';
import thumbGallery10 from '../oldassets/gallery/thumbs/thumb10.jpeg';
import thumbGallery11 from '../oldassets/gallery/thumbs/thumb11.jpeg';
import thumbGallery12 from '../oldassets/gallery/thumbs/thumb12.jpeg';
import thumbGallery13 from '../oldassets/gallery/thumbs/thumb13.jpeg';
import thumbGallery14 from '../oldassets/gallery/thumbs/thumb14.jpeg';
import thumbGallery15 from '../oldassets/gallery/thumbs/thumb15.jpg';
import thumbGallery16 from '../oldassets/gallery/thumbs/thumb16.jpg';
import thumbGallery17 from '../oldassets/gallery/thumbs/thumb17.jpeg';
import { Link } from 'react-router-dom';

function AboutPage() {
  return (
      <div>
        <div id="holder">
    <div id="header">

       
      <div id="logo"><a href="https://cdapainting.com/home"><img src={logo}
            alt="CDA Painting Solutions"/></a></div>
      <div id="moto"><img style={{verticalAlign: 'middle', marginTop: '-12px'}}
          src={telicon} alt="" width="43"
          height="43"/>&nbsp;&nbsp;<strong><span style={{fontSize: 'xx-large'}}>0419 301 725<br /></span></strong>
      </div>
    </div>
    <div id="mainNav">

      <div id="menuwrapper">
        <ul id="primary-nav">

        <li>
            <Link to="/"><span>Home</span></Link>
          </li>
          <li>
            <Link to="/about-us"><span>About Us</span></Link>
          </li>
          <li>
            <Link to="/testimonials"><span>Testimonials</span></Link>
          </li>
          <li class="menuactive">
            <Link className="menuactive" to="/gallery"><span>Gallery</span></Link>
          </li>
        </ul>
        <div className="clearb"></div>
      </div>

    </div>
    <div id="banner"><img src={banner} alt="" width="960" height="206"/>
    </div>
   
    <div id="content">
    <div id="col-1">
      <div id="col-2"> <h1>Gallery</h1>
<p></p><div className="gallery">
<div className="pagenavigation">
</div>

	<div className="img">
	   <a className="group" href={gallery01} title="0-jpg.jpg" rel="gallery-1"><img src={thumbGallery01} alt="0-jpg.jpg"/></a>
		</div>
	<div className="img">
	   <a className="group" href={gallery02} title="2-jpg.jpg" rel="gallery-1"><img src={thumbGallery02} alt="2-jpg.jpg"/></a>
		</div>
	<div className="img">
	   <a className="group" href={gallery03} title="4-jpg.jpg" rel="gallery-1"><img src={thumbGallery03} alt="4-jpg.jpg"/></a>
		</div>
	<div className="img">
	   <a className="group" href={gallery04} title="6-jpg.jpg" rel="gallery-1"><img src={thumbGallery04} alt="6-jpg.jpg"/></a>
		</div>
	<div className="img">
	   <a className="group" href={gallery05} title="9-3.jpg" rel="gallery-1"><img src={thumbGallery05} alt="9-3.jpg"/></a>
		</div>
	<div className="img">
	   <a className="group" href={gallery06} title="IMG_0009.JPG" rel="gallery-1"><img src={thumbGallery06} alt="IMG_0009.JPG"/></a>
		</div>
	<div className="img">
	   <a className="group" href={gallery07} title="IMG_0012.JPG" rel="gallery-1"><img src={thumbGallery07} alt="IMG_0012.JPG"/></a>
		</div>
	<div className="img">
	   <a className="group" href={gallery08} title="IMG_0212.JPG" rel="gallery-1"><img src={thumbGallery08} alt="IMG_0212.JPG"/></a>
		</div>
	<div className="img">
	   <a className="group" href={gallery09} title="IMG_0222.JPG" rel="gallery-1"><img src={thumbGallery09} alt="IMG_0222.JPG"/></a>
		</div>
	<div className="img">
	   <a className="group" href={gallery10} title="IMG_0312.JPG" rel="gallery-1"><img src={thumbGallery10} alt="IMG_0312.JPG"/></a>
		</div>
	<div className="img">
	   <a className="group" href={gallery11} title="IMG_0313.JPG" rel="gallery-1"><img src={thumbGallery11} alt="IMG_0313.JPG"/></a>
		</div>
	<div className="img">
	   <a className="group" href={gallery12} title="IMG_0340.JPG" rel="gallery-1"><img src={thumbGallery12} alt="IMG_0340.JPG"/></a>
		</div>
	<div className="img">
	   <a className="group" href={gallery13} title="IMG_0509.JPG" rel="gallery-1"><img src={thumbGallery13} alt="IMG_0509.JPG"/></a>
		</div>
	<div className="img">
	   <a className="group" href={gallery14} title="IMG_0629.JPG" rel="gallery-1"><img src={thumbGallery14} alt="IMG_0629.JPG"/></a>
		</div>
	<div className="img">
	   <a className="group" href={gallery15} title="IMG_0872.jpg" rel="gallery-1"><img src={thumbGallery15} alt="IMG_0872.jpg"/></a>
		</div>
	<div className="img">
	   <a className="group" href={gallery16} title="IMG_1146.jpg" rel="gallery-1"><img src={thumbGallery16} alt="IMG_1146.jpg"/></a>
		</div>
	<div className="img">
	   <a className="group" href={gallery17} title="IMG_8999.JPG" rel="gallery-1"><img src={thumbGallery17} alt="IMG_8999.JPG"/></a>
		</div>
<div className="galleryclear">&nbsp;</div>
</div>

<div id="fancybox-tmp" style={{padding: '50px'}}></div>

<p></p> </div>
    </div>
  </div>

    <div id="footerHolder">
      <div id="footer">
        <div id="footerNav">


          <ul>

            <li><a href="https://cdapainting.com/"><span>Home</span></a>


            </li>

            <li><a href="https://cdapainting.com/about-us"><span>About Us</span></a>


            </li>

            <li><a href="https://cdapainting.com/testimonials"><span>Testimonials</span></a>


            </li>

            <li className="menuactive"><a className="menuactive" href="https://cdapainting.com/gallery"><span>Gallery</span></a>


            </li>
          </ul>
        </div>
        <div id="footerTxt">
          <p>Copyright © 2020 | All Rights Reserved
           </p>
        </div>
      </div>
    </div>
  </div>
      </div>
  );
}

export default AboutPage;
