import React from 'react';
import './index.css';
import logo from '../oldassets/logo.png';
import telicon from '../oldassets/ico_tel.png';
import banner from '../oldassets/banner_01.jpg';
import { Link } from 'react-router-dom';

function HomePage() {
  return (
      <div>
        <div id="holder">
    <div id="header">
      <div id="logo"><a href="https://cdapainting.com/home"><img src={logo}
            alt="CDA Painting Solutions"/></a></div>
      <div id="moto"><img style={{verticalAlign: 'middle', marginTop: '-12px'}}
          src={telicon} alt="" width="43"
          height="43"/>&nbsp;&nbsp;<strong><span style={{fontSize: 'xx-large'}}>0419 301 725<br /></span></strong>
      </div>
    </div>
    <div id="mainNav">

      <div id="menuwrapper">
        <ul id="primary-nav">

          <li class="menuactive">
            <Link className="menuactive" to="/"><span>Home</span></Link>
          </li>
          <li>
            <Link to="/about-us"><span>About Us</span></Link>
          </li>
          <li>
            <Link to="/testimonials"><span>Testimonials</span></Link>
          </li>
          <li>
            <Link to="/gallery"><span>Gallery</span></Link>
          </li>
        </ul>
        <div class="clearb"></div>
      </div>

    </div>
    <div id="banner"><img src={banner} alt="" width="960" height="206"/>
    </div>
    <div id="content">
      <div id="col-1">
        <div id="col-2">
          <h1>Whatever era <strong><span style={{color: '#ff0000'}}>CDA Painting</span></strong> has the Solution</h1>
          <hr />
          <h2>What makes <span style={{color: '#ed1c24'}}><strong>CDA Painting Solutions</strong></span> different from the
            others ?</h2>
          <p>With over 15 year’s experience, CDA has delivered outstanding results across all styles and periods of
            homes, &nbsp;located within a 15km radius of&nbsp; Melbourne CBD.</p>
          <p>Our approach is simple,&nbsp; the job is not finished until you the client are 100% happy with the finished
            product.&nbsp; Unlike others, we can provide and co-ordinate additional trades and services to complete your
            project.</p>
          <p>This can include:-</p>
          <ul>
            <li>Plaster repairs to period standard</li>
            <li>Restoration of timber work</li>
            <li>Treatment of Rising Damp</li>
            <li>Replacement of Spouting</li>
            <li>Carpentry incl. Window repairs ; Sashcord replacement</li>
            <li>Glazing incl. leadlighting</li>
            <li>Timber Floor renovation</li>
          </ul>
          <p>Whatever your budget we guarantee exquisite detailing and craftsmanship in all our work.</p>
          <h2>SERVICES WE OFFER</h2>
          <p>We offer a wide range of painting applications to meet all your requirements, including</p>
          <table border="0" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td width="300">
                  <ul>
                    <li>Body Corporate work</li>
                    <li>Residential</li>
                    <li>Exterior and Interior</li>
                    <li>Wallpapering – removal and/or replacement</li>
                    <li>Feature Walls</li>
                    <li>Anti-Graffiti Coatings</li>
                  </ul>
                </td>
                <td width="300">
                  <ul>
                    <li>Decorative finishes</li>
                    <li>Heat-reflective coatings</li>
                    <li>Timber Staining incl. Decking</li>
                    <li>Rust Treatment and converting</li>
                    <li>Concrete and masonry coatings</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <p>All preparation and painting works are undertaken to Master Painter’s Association Industry Standards for
            the Surface Coating Industry.</p>
        </div>
      </div>
    </div>
    <div id="footerHolder">
      <div id="footer">
        <div id="footerNav">


          <ul>

            <li class="menuactive"><a class="menuactive" href="https://cdapainting.com/"><span>Home</span></a>


            </li>

            <li><a href="https://cdapainting.com/about-us"><span>About Us</span></a>


            </li>

            <li><a href="https://cdapainting.com/testimonials"><span>Testimonials</span></a>


            </li>

            <li><a href="https://cdapainting.com/gallery"><span>Gallery</span></a>


            </li>
          </ul>
        </div>
        <div id="footerTxt">
          <p>Copyright © 2020 | All Rights Reserved
           </p>
        </div>
      </div>
    </div>
  </div>
      </div>
  );
}

export default HomePage;
